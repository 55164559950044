<template>
  <Report :user-id="user.id" report-title="My work status report"/>
</template>

<script setup>
import Report from "@/views/page/company/contact/work-status/Report.vue";
import {useStore} from "vuex";
import {computed} from "vue";

const store = useStore()
const user = computed(() => store.state.user.user);
</script>
